import React from 'react'
import Link from './core/Link'

const TextLink = ({ children, ...props }) => (
  <Link borderBottom="1px solid currentColor" {...props}>
    {children}
  </Link>
)

export default TextLink
