import React from 'react'
import { graphql } from 'gatsby'

import SEO from '~/components/seo'
import Container from '../components/ui/Container'
import Heading from '../components/ui/Heading'
import { Flex, Box } from 'reflexbox'
import Text from '../components/ui/core/Text'
import ProductImage from '../components/ui/ProductImage'
import TextLink from '../components/ui/TextLink'

const About = ({ data }) => {
  return (
    <>
      <SEO title="About" />
      <Container>
        <Flex justifyContent="center" my={4} flexWrap="wrap">
          <Box width={[1, 1 / 2, 1 / 2]} pr={[0, 3, 4, 6]}>
            <ProductImage
              sizes="680px, (max-width: 1024px) 640px, (max-width: 1440px) 1060px"
              fluid={data.one.childImageSharp.fluid}
              mb={4}
            />
            <ProductImage
              sizes="680px, (max-width: 1024px) 640px, (max-width: 1440px) 1060px"
              fluid={data.two.childImageSharp.fluid}
              mb={4}
            />
            <ProductImage
              sizes="680px, (max-width: 1024px) 640px, (max-width: 1440px) 1060px"
              fluid={data.three.childImageSharp.fluid}
              mb={4}
            />
          </Box>
          <Box width={[1, 1 / 2, 1 / 2]}>
            <Heading mb={4} level={2} as="h1">
              What is Prospect Leather?
            </Heading>
            <Text fontSize={[3, 3, 4]}>
              Prospect Leather is a one-man shop for hand-crafted leather goods.
            </Text>
            <Heading level={3} mt={4} mb={2} as="h2">
              The shop
            </Heading>
            <Text fontSize={[2, 2, 3]}>
              Most of my work is commission-based, but I also sell a select few
              items for general sale. That means that as well as ready-to-ship
              items, you'll find items that have already been made and sold to
              another customer, but you get the opportunity to have your own
              version made to your exact requirements.
            </Text>
            <Text fontSize={[2, 2, 3]}>
              If you have an idea for something that don't see, thats great! I
              love working on new creative ideas — just{' '}
              <TextLink color="blue" to="/custom">
                tell me what you're looking for.
              </TextLink>
            </Text>
            <Heading level={3} mt={4} mb={2} as="h2">
              The craft
            </Heading>
            <Text fontSize={[2, 2, 3]}>
              Everything I make is created by hand using a combination of
              traditional and modern techniques. My work is detail-driven with a
              focus on creating goods for everyday use that will last a
              lifetime.
            </Text>
            <Text fontSize={[2, 2, 3]}>
              Each item takes hours or even days to make, and every part of the
              process from the cutting and stitching to the polishing and
              finishing is done by hand in my small home workshop in Brooklyn,
              NY.
            </Text>
            <Text fontSize={[2, 2, 3]}>
              I don't outsource anything and nothing is mass produced — all my
              work is created one at a time so I really get to focus on the
              details and individual characteristics of each piece.
            </Text>
            <Heading level={3} mt={4} mb={2} as="h2">
              The leather
            </Heading>
            <Text fontSize={[2, 2, 3]}>
              Quality materials are the foundation of any craft, and my work is
              no exception.
            </Text>
            <Text fontSize={[2, 2, 3]}>
              I've spent years sourcing the best leather and building
              relationships with tanneries and suppliers around the world, from
              French Chevre goatskin to traditional English Bridle, Japanese
              Shell Cordovan to quality vegetable tanned leathers made right
              here in the United States.
            </Text>
            <Text fontSize={[2, 2, 3]}>
              Every different leather has different characteristics that suit
              different projects, so getting to know the materials is a big part
              of the craft. For custom orders, I work closesly with clients to
              match thier style and preferences to leather that works best for
              the product.
            </Text>
            {/* <Heading level={3} mt={4} mb={2} as="h2">
              The maker
            </Heading>
            <Text fontSize={[2, 2, 3]}>
              I'm Aaron — a British expat who moved to New York in 2018. My
              background is in Design and Technology, so I love taking my
              creativity and attention to detail to an analog medium like
              leathercraft.
            </Text> */}
          </Box>
        </Flex>
      </Container>
    </>
  )
}

export const query = graphql`
  query aboutUs {
    one: file(relativePath: { eq: "card-holders.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1060) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    two: file(relativePath: { eq: "wash-bag.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1060) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    three: file(relativePath: { eq: "passport-case.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1060) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
  }
`

export default About
